import type { PointOfInterestType } from '@volvo/vce-package-site-mapcommon';

export enum MachineType {
  HAULER = 'hauler',
  ARTICULATED_HAULER = 'articulated-hauler',
  DRILL_RIG = 'drill-rig',
  WHEEL_LOADER = 'wheel-loader',
  TRUCK = 'truck',
  CAR = 'car',
  EXCAVATOR = 'excavator',
  COMPACTOR = 'compactor',
  DOZER = 'dozer',
  GRADER = 'grader',
  SCRAPER = 'scraper',
  PAVER = 'paver',
  DEMOLITION = 'demolition',
  PIPELAYER = 'pipelayer',
  PEDESTRIAN = 'pedestrian',
  OTHER = 'other',
}

export const FlowPoiTypes: Array<keyof typeof PointOfInterestType> = [
  'GATE',
  'WEIGHBRIDGE',
  'CRUSHER',
  'FACTORY',
  'SILO',
  'TRAIN',
];

export const notificationBadgeIds = {
  deviations: 'deviations',
  materialMapping: 'materialMapping',
  unassignedLoadTickets: 'unassignedLoadTickets',
} as const;
